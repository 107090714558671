<template>
  <div>
    <div class="flex items-end mb-8 w-full">
      <div>
        <Title title="Campaigns" size="large" class="mb-2" />

        <span class="title__label">
          Showing
          <span class="font-bold">
            {{ getCampaigns != null ? getCampaigns.length : 0 }}
          </span>
          campaigns
        </span>
      </div>
    </div>

    <div v-if="getCampaigns && getCampaigns.length" class="grid md:flex grid-cols-1 gap-8 md:gap-0">
      <CampaignCard
        v-for="(campaign, mobileCampaignIndex) of getCampaigns"
        :key="mobileCampaignIndex"
        class="md:hidden m-auto w-full"
        :title="campaign.title"
        :image-src="
          campaign.thumbnail ||
          'https://storage.googleapis.com/overlay-sources/9daac7f7-5a05-4cfd-9035-5c14b39bab4anetwrk_campaign_fallback.jpg'
        "
        :brand="campaign.brand.title || ''"
        :brand-image="
          brandImages[campaign.brand.id] ||
          'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'
        "
        :url="`/campaigns/${campaign._id}`"
      />

      <List
        class="hidden md:flex list--campaigns"
        :sort="[
          {
            label: 'Campaign',
            prop: 'date',
            active: true,
          },
          {
            label: 'Views',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Clicks',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Times displayed',
            prop: 'streamerAmount',
            align: 'center',
          },
          {
            label: 'Streamers',
            prop: 'streamerAmount',
            align: 'center',
          },
          /* {
            label: 'Targets',
            prop: 'date',
            align: 'center',
          }, */
          {
            label: '',
            prop: '',
          },
        ]"
      >
        <li v-for="(campaign, desktopCampaignIndex) of getCampaigns" :key="desktopCampaignIndex" class="list__item">
          <div class="list__item-img">
            <img
              v-lazy="
                brandImages[campaign.brand.id] ||
                'https://storage.googleapis.com/cavea-avatar-service/twitter/netwrkgg/avatar.png'
              "
            />
          </div>

          <div class="list__item-content">
            <div class="list__item-col" data-col="1">
              <div class="flex items-center">
                <Button
                  class="text-base font-medium button ellipsis"
                  :url="`/campaigns/${campaign._id}`"
                  :label="campaign.title"
                  kind="link"
                />

                <div
                  class="ml-1 w-2 h-2 rounded-full"
                  :class="[campaign && campaign.active ? 'bg-signal-green' : 'bg-signal-red']"
                ></div>
              </div>

              <div class="flex items-center w-full">
                <span class="text-xs ellipsis">
                  {{ new Date(campaign.date) | moment("Do MMM YYYY") }}
                </span>

                <div class="flex gap-4 ml-auto">
                  <Tag v-if="campaign.orgId !== getOrganization._id" label="Non-owned" :random-color="true" />

                  <Tag
                    v-if="campaign._id && campaignProgress(campaign._id)"
                    class="flex place-content-center w-12 text-center"
                    :label="`${campaignProgress(campaign._id).percentage}%`"
                    :color="campaignProgress(campaign._id).color"
                  />

                  <Tag
                    v-if="campaign.public !== null"
                    class="flex place-content-center w-16 text-center"
                    :label="campaign.public ? 'Public' : 'Private'"
                    color="rgb(23, 18, 39)"
                  />
                </div>
              </div>
            </div>

            <div class="items-center list__item-col" data-col="2" title="Total campaign views">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignViews(campaign._id) }}
              </Button>
            </div>
            <div class="items-center list__item-col" data-col="3" title="Total campaign clicks">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignLinkClicks(campaign._id) }}
              </Button>
            </div>
            <div class="items-center list__item-col" data-col="4" title="Times displayed">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ getCampaignDisplayTimes(campaign._id) }}
              </Button>
            </div>

            <div class="items-center list__item-col" data-col="5" title="Amount of streamers">
              <Button class="mb-2 text-sm font-medium button ellipsis" kind="link">
                {{ campaign.handles.length || 0 }}
              </Button>
            </div>

            <div class="list__item-col" data-col="0">
              <Dropdown
                :position="['right']"
                :items="[
                  {
                    label: 'View campaign',
                    url: `/campaigns/${campaign._id}`,
                  },
                  {
                    id: `${campaign._id}`,
                    label: 'Edit campaign',
                  },
                  {
                    id: `${campaign._id}`,
                    label: 'Delete campaign',
                  },
                ]"
                :is-show-more="true"
                @change="dropdownClick"
              />
            </div>
          </div>
        </li>
      </List>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Title, CampaignCard, Button, Tag } from "cavea-design-system";

export default {
  name: "Campaigns",

  metaInfo: {
    title: "Campaigns",
  },

  components: {
    Title,
    CampaignCard,
    List: () => import("@/modules/List"),
    Tag,
    Button,
    Dropdown: () => import("@/components/Dropdown"),
  },

  data() {
    return {
      brandImages: {},
      campaignViews: {},
      campaignClicks: {},
      campaignCTR: {},
      campaignDisplayTimes: {},
      campaignStatus: {},
    };
  },

  computed: {
    ...mapGetters(["getBrands", "getUserInfo", "getCampaigns", "getOrganization"]),
  },

  watch: {
    getCampaigns: {
      handler() {
        if (Object.keys(this.brandImages).length === 0) {
          this.getBrandImg();
        }
      },
    },
  },

  created() {
    if (this.getUserInfo?.type === "streamer") {
      this.$router.push("/");
    }

    this.getBrandImg();
  },

  methods: {
    ...mapMutations(["setExistingCampaign"]),

    ...mapActions(["fetchCampaigns"]),

    /**
     * @summary detect which button is clicked in campaign dropdown
     * @param {event} e
     */
    async dropdownClick(e) {
      // FIXME: There must be a better way to detect which item is clicked
      if (e?.item?.label?.toLowerCase() === "edit campaign") {
        this.editCampaign(e.item.id);
      } else if (e?.item?.label?.toLowerCase() === "delete campaign") {
        await this.$swal({
          title: `This has been temporarily disabled`,
          text: "",
          icon: "info",
          showCancelButton: false,
          confirmButtonText: "Ok fam squad",
        });
        /* const confirmation = await this.$swal({
          title: `Are you sure you want to delete this campaign?`,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: "Yes, delete it!",
        });

        if (confirmation?.isConfirmed) {
          this.deleteCampaign(e.item.id);
        } */
      }
    },

    /**
     * @summary delete AdCampaign by id
     * @param {string} id - AdCampaign ObjectId
     */
    async deleteCampaign(id) {
      // TODO: Confirmation on deletion

      const URL = `${process.env.VUE_APP_AVATAR_SERVICE}/ad-campaign/${id}`;
      await axios
        .delete(URL)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Campaign has been deleted",
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch((err) => {
          console.error("deleteCampaign", err);

          this.$swal({
            icon: "error",
            title: "Oops, something went wrong!",
            text: "Please try again later",
          });
        });

      this.fetchCampaigns();
    },

    /**
     * @summary edit campaign by id
     * @param {string} id - AdCampaign ObjectId
     */
    editCampaign(id) {
      this.setExistingCampaign(id);

      this.$router.push("/create/campaign/settings");
    },

    /**
     * @summary get times ad has been displayed
     * @returns {number}
     */
    getCampaignDisplayTimes(id) {
      if (this.campaignDisplayTimes[id] !== null && this.campaignDisplayTimes[id] !== undefined) {
        return this.campaignDisplayTimes[id];
      }

      let displayCount = 0;

      const campaign = this.getCampaigns?.find((c) => c?._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.statsId?.stats?.length) {
            displayCount += campaign.handles[i].statsId.stats.length;
          }
        }
      }

      this.campaignDisplayTimes[id] = displayCount;
      return displayCount;
    },

    /**
     * @summary get amount of link clicks
     * @returns {number}
     */
    getCampaignLinkClicks(id) {
      if (this.campaignClicks[id] !== null && this.campaignClicks[id] !== undefined) {
        return this.campaignClicks[id];
      }

      let linkClicks = 0;

      const campaign = this.getCampaigns?.find((c) => c?._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.redirectorId?.stats?.length) {
            linkClicks += campaign.handles[i].redirectorId.stats.length;
          }
        }
      }

      this.campaignClicks[id] = linkClicks;
      return linkClicks;
    },

    /**
     * @summary get campaign total ad views
     * @param {string} id
     * @returns {number} total views
     */
    getCampaignViews(id) {
      if (this.campaignViews[id] !== null && this.campaignViews[id] !== undefined) {
        return this.campaignViews[id];
      }

      let totalViews = 0;

      const campaign = this.getCampaigns?.find((c) => c?._id === id);

      if (campaign?.handles?.length) {
        for (let i = 0; i < campaign.handles.length; ++i) {
          if (campaign?.handles[i]?.statsId?.stats?.length)
            for (let j = 0; j < campaign.handles[i].statsId.stats.length; ++j) {
              if (campaign?.handles[i]?.statsId?.stats[j]?.views) {
                totalViews += campaign?.handles[i]?.statsId?.stats[j]?.views || 0;
              }
            }
        }
      }

      this.campaignViews[id] = totalViews;

      return totalViews;
    },

    /**
     * @summary get campaign link ctr
     * @returns {number} ctr
     */
    getCampaignLinkCtr(id) {
      if (this.campaignCTR[id] !== null && this.campaignCTR[id] !== undefined) {
        return this.campaignCTR[id];
      }

      let ctr = 0;
      const views = this.getCampaignViews(id);
      const clicks = this.getCampaignLinkClicks(id);

      if (views && clicks) {
        ctr = ((clicks / views) * 100)?.toFixed(2);
      }

      this.campaignCTR[id] = ctr;

      return ctr;
    },

    /**
     * @summary get campaign average ad views
     * @returns {number} avgViews
     */
    getCampaignAverageViews(id) {
      let avg = 0;
      const views = this.getCampaignViews(id);
      const displayTimes = this.getCampaignDisplayTimes(id);

      if (views && displayTimes) {
        avg = (views / displayTimes)?.toFixed(2);
      }

      return avg;
    },

    async getBrandImg() {
      if (this.getCampaigns) {
        for (const campaign of this.getCampaigns) {
          if (campaign?.brand?.id && this.brandImages[`${campaign?.brand?.id}`] === undefined) {
            const brandIndex = this.getBrands?.findIndex((b) => b?._id === campaign?.brand?.id);

            if (brandIndex !== -1) {
              this.brandImages[`${campaign?.brand?.id}`] = this.getBrands[brandIndex]?.logo || null;
            } else {
              const HOST = process.env.VUE_APP_API_URL;
              const URL = `${HOST}/brand-logo/${campaign?.brand?.id}`;

              await axios
                .get(URL)
                .then((res) => {
                  this.brandImages[`${campaign?.brand?.id}`] = res?.data?.image || null;
                })
                .catch((error) => {
                  console.error("fetchNonOwnedBrandImage error", error);
                  return null;
                });
            }
          }
        }
      }
    },

    /**
     * @param {string} campaignId
     */
    campaignProgress(campaignId) {
      if (this.campaignStatus[campaignId] !== null && this.campaignStatus[campaignId] !== undefined) {
        return this.campaignStatus[campaignId];
      }

      let percentageDone = 0;

      if (campaignId) {
        const campaignIndex = this.getCampaigns?.findIndex((c) => c._id === campaignId);

        if (campaignIndex !== -1) {
          const targets = this.getCampaigns[campaignIndex]?.targets;

          let expectedViews = 0;

          if (targets?.length) {
            for (const target of targets) {
              if (
                target?.unit?.id === "views" &&
                target?.value &&
                typeof target?.value === "number" &&
                !Number.isNaN(parseInt(target?.value, 10))
              ) {
                if (target.value > expectedViews) {
                  expectedViews = target.value;
                }
              }
            }
          }

          const totalViews = this.getCampaignViews(campaignId);

          percentageDone = totalViews > expectedViews ? 100 : Math.floor((totalViews / expectedViews) * 100);
        }
      }

      const color = `rgba(52, 198, 110, ${Math.max(
        Math.floor(percentageDone / 10) * 8,
        percentageDone === 100 ? 100 : 10
      )}%)`;

      this.campaignStatus[campaignId] = {
        percentage: percentageDone,
        color,
      };

      return this.campaignStatus[campaignId];
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
